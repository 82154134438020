import React from 'react';
import ContentBox from 'components/Shared/ContentBox/ContentBox';

import './MobileAppIconsBlock.scss';

const IconsBlock = ({ data }) => (
  <div className="aaa-mobileapp-icons-block">
    <ContentBox>
      <h2 className="aaa-mobileapp-icons-block__title">{data.title}</h2>

      <div className="aaa-mobileapp-icons-block__container">
        <div className="row">
          {data.icons.map((item, i) => (
            <div key={i} className="col-12 col-sm-6 col-lg">
              <div className="aaa-mobileapp-icons-block__column">
                <div className="aaa-mobileapp-icons-block__icon-container">
                  <img
                    className="aaa-mobileapp-icons-block__icon"
                    src={item.icon.publicURL}
                  />
                </div>
                <div className="aaa-mobileapp-icons-block__main-wrapper">
                  <div className="aaa-mobileapp-icons-block__icon-title">
                    {item.title}
                  </div>
                  <div
                    className="aaa-mobileapp-icons-block__icon-description"
                    dangerouslySetInnerHTML={{ __html: item.description }}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </ContentBox>
  </div>
);

export default IconsBlock;
