import React from 'react';
import Img from 'gatsby-image';
import { Carousel } from 'react-responsive-carousel';
import ContentBox from 'components/Shared/ContentBox/ContentBox';
import IconArrowLeftLight from 'static/images/icons/chevron-left-light.inline.svg';
import IconArrowRightLight from 'static/images/icons/chevron-right-light.inline.svg';

import './MobileAppInfoBlock.scss';

const MobileAppInfoBlock = ({ data }) => (
  <div className="aaa-mobileapp-infoblock">
    <ContentBox>
      <h2 className="aaa-mobileapp-infoblock__title">{data.title}</h2>
      {data.subtitle && (
        <div className="aaa-mobileapp-infoblock__subtitle">{data.subtitle}</div>
      )}
      <div className="aaa-mobileapp-infoblock__cards-container">
        <div className="row justify-content-center">
          {data.infoCards.map((item, i) => (
            <div key={i} className="col-4 aaa-mobileapp-infoblock__card">
              <InfoCard data={item} />
            </div>
          ))}
        </div>
      </div>

      <div className="aaa-mobileapp-infoblock__cards-container-mobile">
        <Carousel
          className="aaa-products__carousel"
          infiniteLoop={false}
          showThumbs={false}
          showStatus={false}
          showArrows={true}
          showIndicators={true}
          swipeScrollTolerance={100}
          verticalSwipe="natural"
          renderArrowNext={(clickHandler, hasNext) => (
            <button
              disabled={!hasNext}
              className="control-arrow control-next"
              aria-label="next slide/item"
              onClick={clickHandler}
            >
              <IconArrowRightLight className="light" />
            </button>
          )}
          renderArrowPrev={(clickHandler, hasPrev) => (
            <button
              disabled={!hasPrev}
              className="control-arrow control-prev"
              aria-label="previous slide/item"
              onClick={clickHandler}
            >
              <IconArrowLeftLight className="light" />
            </button>
          )}
        >
          {data.infoCards.map((item, i) => (
            <InfoCard data={item} key={i} />
          ))}
        </Carousel>
      </div>
    </ContentBox>
  </div>
);

const InfoCard = ({ data }) => (
  <div className="aaa-mobileapp-infocard">
    <div className="aaa-mobileapp-infocard__image-container">
      <Img
        className="aaa-mobileapp-infocard__image"
        fluid={data.image.childImageSharp.fluid}
      />
    </div>
    <div className="aaa-mobileapp-infocard__content-container">
      <div className="aaa-mobileapp-infocard__title">{data.title}</div>
      {data.description && (
        <div
          className="aaa-mobileapp-infocard__description"
          dangerouslySetInnerHTML={{ __html: data.description }}
        />
      )}
    </div>
  </div>
);

export default MobileAppInfoBlock;
