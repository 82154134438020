import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/Layout/Layout';
import SEO from 'components/SEO/SEO';
import ZipGateContainer from 'components/ZipGateContainer/ZipGateContainer';
import Hero from 'components/Hero/Hero';
import MobileAppInfoBlock from 'components/MobileAppInfoBlock';
import MobileAppIconsBlock from 'components/MobileAppIconsBlock';
import InfoCardBig from 'components/InfoCardBig/InfoCardBig';
import CTAForm from 'components/CTAForm';

import ScrollToTopButton from 'components/ScrollToTopButton';


const MobileAppPage = ({ data, location }) => {
  return (
    <Layout location={location} type="standard">
      <SEO meta={data.mobileAppYaml.metaTags} title={data.mobileAppYaml.title} />  
      <Hero
        data={data.mobileAppYaml.hero}
      />
      <MobileAppInfoBlock data={data.mobileAppYaml.infoBlock} />
      <MobileAppIconsBlock data={data.mobileAppYaml.iconsBlock}/>
      {data.mobileAppYaml.infoCards.map((card, i) => (<InfoCardBig key={i} data={card} />))}
      <CTAForm />
      <ZipGateContainer location={location}/>
      <ScrollToTopButton />
    </Layout>
  );
};

export default MobileAppPage;

export const pageQuery = graphql`
  query MobileAppPageQuery {
    mobileAppYaml {
      id
      title
      
      hero {
        size
        title
        heroImage {
            childImageSharp {
              fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
        }
        heroImagePosition
        appLinks {
          appStore
          googlePlay
        }
      }

      infoBlock {
          title
          subtitle
          infoCards {
              title
              description
              image {
                childImageSharp {
                    fluid(maxWidth: 308, maxHeight: 547) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
              }
          }
      }

      iconsBlock {
          title
          icons {
              title
              description
              icon {
                  publicURL
              }
          }
      }

      infoCards {
          color
          type
          icon {
            publicURL
          }
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 891, maxHeight: 521) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
      }
    }
  }
`;
