import React from 'react';
import { MetricsElement } from 'react-metrics';
import Img from 'gatsby-image';
import { withHidePopUpButton } from 'components/CTAForm';
import {
  LinkArrowButtonPrimary,
  LinkArrowButtonSecondary,
  CTAButton,
} from 'components/Shared/Button/Button';
import Link from 'components/Shared/Link/Link';
import ContentBox from 'components/Shared/ContentBox/ContentBox';
import GooglePlayBadge from 'static/images/play-store-badge.svg';
import AppStoreBadge from 'static/images/app-store-badge.svg';

import './Hero.scss';

const Hero = ({ data }) => (
  <div className={`hero-${data.size}`}>
    <Img
      className="hero__image"
      fluid={data.heroImage.childImageSharp.fluid}
      imgStyle={
        data.heroImagePosition ? { objectPosition: data.heroImagePosition } : {}
      }
      loading="eager"
      fadeIn={false}
    />
    <div className="hero__gradient" />
    <div className="hero__overlay">
      <ContentBox>
        <div className="row no-gutters">
          <div className="col-10 col-md-7 col-lg-7">
            <div className="hero__cta-card">
              { data.badge && (
                <div className="hero__badge">
                  <div className="hero__badge__prefix">{data.badge.prefix}</div>
                  <div 
                    className="hero__badge__body"
                    dangerouslySetInnerHTML={{__html: data.badge.body}}
                  />
                  <div className="hero__badge__postfix">{data.badge.postfix}</div>
                </div> 
              )}
              { data.caption && (
                <div className="hero__caption">{data.caption}</div>
              )}
              <h1
                className="hero__title"
                dangerouslySetInnerHTML={{
                  __html: data.title,
                }}
              />
              <div className="hero__subtitle-container">
                <h3 
                  className="hero__subtitle"
                  dangerouslySetInnerHTML={{
                    __html: data.subtitle,
                  }}
                />
                {data.disclaimer && (
                  <div 
                    className="hero__disclaimer"
                    dangerouslySetInnerHTML={{
                      __html: data.disclaimer,
                    }}
                  />
                )}
                <div className="hero__buttons-container">
                  {data.primaryButton && (
                    <MetricsElement>
                      {data.primaryButton.url && data.primaryButton.url !== '#' ? (
                        <LinkArrowButtonPrimary
                          className="hero__cta-button"
                          data-metrics-event-name="hero_cta_click"
                          data-metrics-label={data.primaryButton.text}
                          to={data.primaryButton.url}
                        >
                          {data.primaryButton.text}
                        </LinkArrowButtonPrimary>
                      ) : (
                        <CTAButton
                          className="hero__cta-button"
                          data-metrics-event-name="hero_cta_click"
                          data-metrics-label={data.primaryButton.text}
                        >
                          {data.primaryButton.text}
                        </CTAButton>
                      )}
                    </MetricsElement>
                  )}
                  {data.secondaryButton && (
                    <LinkArrowButtonSecondary to={data.secondaryButton.url}>
                      {data.secondaryButton.text}
                    </LinkArrowButtonSecondary>
                  )}
                </div>
                {data.appLinks &&
                <div className="hero__app-links-container">
                  <Link to={data.appLinks.appStore}>
                    <img
                      className="hero__app-store-badge"
                      src={AppStoreBadge}
                    />
                  </Link>
                  <Link to={data.appLinks.googlePlay}>
                    <img
                      className="hero__google-play-badge"
                      src={GooglePlayBadge}
                    />
                  </Link>
                </div>}
              </div>
            </div>
          </div>
        </div>
      </ContentBox>
    </div>
  </div>
);

export default withHidePopUpButton(Hero, { top: 100 });
